
// tslint:disable
import { CommerceProperty } from '@msdyn365-commerce/retail-proxy';
import * as Entities from '@msdyn365-commerce/retail-proxy';
import * as EntityClasses from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceModels.g';

  /**
    * GCFitmentSearchCriteria entity interface.
    */
   export interface IGCFitmentSearchCriteria {
    CategoryId?: string;
    Year?: string;
    Make?: string;
    Model?: string;
    CallType?: string;
    Keyword?: string;
    }

/**
* ProductDetails entity interface.
*/
export interface IProductDetails {
  OdataContext?: string;
  value?: IValue[];
}

export interface IProductETAInfo {
  ErrorMessage?: string;
  ETADate?: string;
  ResultMessage?: string;
}

/**
* Value entity interface.
*/
export interface IValue {
  OdataEtag?: string;
  dataAreaId?: string;
  ItemId?: string;
  AltItemId?: string;
  UseAltItemId?: string;
}

   /**
    * GCFitmentFilterDataEntity entity interface.
    */
    export interface IGCFitmentFilterDataEntity {
      Year?: string;
      Make?: string;
      Model?: string;
      ExtensionProperties?: CommerceProperty[];
      }

/**
* APIConfiguration entity interface.
*/
export interface IAPIConfiguration {
  ClientId?: string;
  ClientSecret?: string;
  Resource?: string;
  GrantType?: string;
  APIUrl?: string;
  LoginUrl?: string;
  AnnotationUrl?: string;
}


    /**
    * ProductSpecificationsEntity entity interface.
    */
     export interface IProductSpecificationsEntity {
      Product_Id?: string;
      Specification_Id?: string;
      Public_Display_Name?: string;
      Product_Attirbute_Value?: string;
      ExtensionProperties?: CommerceProperty[];
      }


  /**
    * ProductInterchangesEntity entity interface.
    */
   export interface IProductInterchangesEntity {
    Product_Id?: string;
    Interchange_Id?: string;
    Public_Display_Name?: string;
    Interchange_Part_Number?: string;
    Interchange_Type_Code?: string;
    ExtensionProperties?: CommerceProperty[];
    }


/**
* APIConfiguration entity class.
*/
export class APIConfigurationExtensionClass implements IAPIConfiguration {

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public ClientId: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public ClientSecret: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Resource: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public GrantType: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public AlternatItemeUrl: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public LoginUrl: string;

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.ClientId = odataObject.ClientId;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.ClientSecret = odataObject.ClientSecret;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.Resource = odataObject.Resource;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.GrantType = odataObject.GrantType;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.AlternatItemeUrl = odataObject.AlternatItemeUrl;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.LoginUrl = odataObject.LoginUrl;

  }
}


/**
 * ProductDetails entity class.
 */
export class ProductDetailsExtensionClass implements IProductDetails {

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public OdataContext: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public value:
    IValue[];

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {

    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.OdataContext = odataObject.OdataContext;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.value = undefined;
    if (odataObject.value) {
      this.value = [];
      for (var i = 0; i < odataObject.value.length; i++) {
        if (odataObject.value[`${i}`]) {
          if (odataObject.value[`${i}`]['@odata.type']) {
            var className: string = odataObject.value[`${i}`]['@odata.type'];
            className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
            if (EntityClasses[`${className}`]) {
              this.value[`${i}`] = new EntityClasses[`${className}`](odataObject.value[`${i}`]);
            }
          } else {
            this.value[`${i}`] = new ValueExtensionClass(odataObject.value[`${i}`]);
          }

        } else {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.value[`${i}`] = undefined;
        }
      }
    }

  }
}

/**
 * Value entity class.
 */
export class ValueExtensionClass implements IValue {

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public OdataEtag: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public dataAreaId: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public ItemId: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public AltItemId: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public UseAltItemId: string;

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {

    odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.OdataEtag = odataObject.OdataEtag;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.dataAreaId = odataObject.dataAreaId;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.ItemId = odataObject.ItemId;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.AltItemId = odataObject.AltItemId;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.UseAltItemId = odataObject.UseAltItemId;

  }
}

/**
   * GCFitmentSearchCriteria entity class.
   */
 export class GCFitmentSearchCriteriaExtensionClass implements IGCFitmentSearchCriteria {

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public CategoryId: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Year: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Make: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Model: string;

// Navigation properties names

/**
 * Construct an object from odata response.
 * @param {any} odataObject The odata result object.
 */
constructor(odataObject?: any) {
odataObject = odataObject || {};
      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      this.CategoryId = odataObject.CategoryId;

      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      this.Year = odataObject.Year;

      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      this.Make = odataObject.Make;

      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      this.Model = odataObject.Model;

}
}


/**
   * GCFitmentFilterDataEntity entity class.
   */
 export class GCFitmentFilterDataEntityExtensionClass implements IGCFitmentFilterDataEntity {

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Year: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Make: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Model: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public ExtensionProperties: CommerceProperty[];

// Navigation properties names

/**
 * Construct an object from odata response.
 * @param {any} odataObject The odata result object.
 */
constructor(odataObject?: any) {
odataObject = odataObject || {};
      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      this.Year = odataObject.Year;

      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      this.Make = odataObject.Make;

      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      this.Model = odataObject.Model;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  this.ExtensionProperties = undefined;
  if (odataObject.ExtensionProperties) {
    this.ExtensionProperties = [];
    for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
      if (odataObject.ExtensionProperties[`${i}`]) {
  if (odataObject.ExtensionProperties[`${i}`]['@odata.type']) {
    var className: string = odataObject.ExtensionProperties[`${i}`]['@odata.type'];
    className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
    if (EntityClasses[`${className}`]) {
      this.ExtensionProperties[`${i}`] = new EntityClasses[`${className}`](odataObject.ExtensionProperties[`${i}`]);
    }
  } else {
    this.ExtensionProperties[`${i}`] = new EntityClasses.CommercePropertyClass(odataObject.ExtensionProperties[`${i}`]);
  }

      } else {
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ExtensionProperties[`${i}`] = undefined;
      }
    }
  }

}
}

/**
   * ProductSpecificationsEntity entity class.
   */
 export class ProductSpecificationsEntityExtensionClass implements IProductSpecificationsEntity {

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Product_Id: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Specification_Id: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Public_Display_Name: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public Product_Attirbute_Value: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public ExtensionProperties: CommerceProperty[];

// Navigation properties names

/**
 * Construct an object from odata response.
 * @param {any} odataObject The odata result object.
 */
constructor(odataObject?: any) {
odataObject = odataObject || {};
      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      this.Product_Id = odataObject.Product_Id;

      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      this.Specification_Id = odataObject.Specification_Id;

      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      this.Public_Display_Name = odataObject.Public_Display_Name;

      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      this.Product_Attirbute_Value = odataObject.Product_Attirbute_Value;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  this.ExtensionProperties = undefined;
  if (odataObject.ExtensionProperties) {
    this.ExtensionProperties = [];
    for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
      if (odataObject.ExtensionProperties[`${i}`]) {
  if (odataObject.ExtensionProperties[`${i}`]['@odata.type']) {
    var className: string = odataObject.ExtensionProperties[`${i}`]['@odata.type'];
    className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
    if (EntityClasses[`${className}`]) {
      this.ExtensionProperties[`${i}`] = new EntityClasses[`${className}`](odataObject.ExtensionProperties[`${i}`]);
    }
  } else {
    this.ExtensionProperties[`${i}`] = new EntityClasses.CommercePropertyClass(odataObject.ExtensionProperties[`${i}`]);
  }

      } else {
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ExtensionProperties[`${i}`] = undefined;
      }
    }
  }

}
}


  /**
   * ProductInterchangesEntity entity class.
   */
   export class ProductInterchangesEntityExtensionClass implements IProductInterchangesEntity {

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Product_Id: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Interchange_Id: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Public_Display_Name: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Interchange_Part_Number: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Interchange_Type_Code: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ExtensionProperties: Entities.CommerceProperty[];

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
  odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Product_Id = odataObject.Product_Id;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Interchange_Id = odataObject.Interchange_Id;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Public_Display_Name = odataObject.Public_Display_Name;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Interchange_Part_Number = odataObject.Interchange_Part_Number;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Interchange_Type_Code = odataObject.Interchange_Type_Code;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.ExtensionProperties = undefined;
    if (odataObject.ExtensionProperties) {
      this.ExtensionProperties = [];
      for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
        if (odataObject.ExtensionProperties[`${i}`]) {
    if (odataObject.ExtensionProperties[`${i}`]['@odata.type']) {
      var className: string = odataObject.ExtensionProperties[`${i}`]['@odata.type'];
      className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
      if (EntityClasses[`${className}`]) {
        this.ExtensionProperties[`${i}`] = new EntityClasses[`${className}`](odataObject.ExtensionProperties[`${i}`]);
      }
    } else {
      this.ExtensionProperties[`${i}`] = new EntityClasses.CommercePropertyClass(odataObject.ExtensionProperties[`${i}`]);
    }

        } else {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.ExtensionProperties[`${i}`] = undefined;
        }
      }
    }

  }
}

export class ProductETAInfo implements IProductETAInfo{
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public ETADate?: string | undefined;
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public ErrorMessage: string;
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public ResultMessage?: string | undefined;
  constructor(odataObject?: any) {
     this.ETADate = odataObject.ETADate;
     this.ErrorMessage = odataObject.ErrorMessage;
     this.ResultMessage = odataObject.ResultMessage;
   }
 }

 /**
    * ItemIds entity interface.
    */
  export interface IItemIds {
    ItemId?: string;
    }


  export class ItemIdsExtensionClass implements IItemIds {

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ItemId: string;

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
  odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ItemId = odataObject.ItemId;

  }
  }

  export interface ProductWarehouses{
    dataAreaId?: string;
    ItemNumber?: string;
    InventorySiteId?: string;
    InventoryWarehouseId?: string;
    AvailableOrderedQuantity?: number;
    OnHandQuantity?: number;
    AvailableOnHandQuantity?: number;
    OnOrderQuantity?: number;
    TotalAvailableQuantity?: number;
    AreWarehouseManagementProcessesUsed?: string;
    DistributionCenter?: string;
  }

  export interface IProductAdditionalDetailsEntity {
    Product_Id?: string;
    CountryLongName?: string;
    ProductLifeCycleDescription?: string;
    Public_Display_Name?: string;
    Product_Attirbute_Value?: string;
    ExtensionProperties?: CommerceProperty[];

    }

    export interface IProductApplicationEntity {
      Make?: string;
      Model?: string;
      Year?: string;
      Qualifier: string[];
      Attribute?: string;
      Locations?: string;
      RecordId?: number;
      Note?: string;
      ExtensionProperties?: CommerceProperty[];
      }

    export class ProductAdditionalDetailsExtensionClass implements IProductAdditionalDetailsEntity {

      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      public CountryLongName: string;
      public ProductLifeCycleDescription: string;
    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
    odataObject = odataObject || {};
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.CountryLongName = odataObject.CountryLongName;
          this.ProductLifeCycleDescription = odataObject.ProductLifeCycleDescription;

    }
    }

    export class ProductApplicationExtensionClass implements IProductApplicationEntity {

      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      public Make: string;
      public Model?: string;
      public Year?: string;
      public Qualifier: string[];
      public Attribute?: string;
      public Locations?: string;
      public Note?: string;

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
    odataObject = odataObject || {};
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/

          this.Make = odataObject.Make;
          this.Model = odataObject.Model;
          this.Year = odataObject.Year;
          this.Qualifier = odataObject.Qualifier;
          this.Note = odataObject.Note;

    }
    }

/**
    * NewAccountEntity entity interface.
    */
 export interface INewAccountEntity {
  emailaddress1?: string;
  firstname?: string;
  lastname?: string;
  telephone1?: string;
  address1_line1?: string;
  address1_stateorprovince?: string;
  address1_city?: string;
  address1_postalcode?: string;
  address1_country?: string;
  companyname?: string;
  websiteurl?: string;
  gc_department?: string;
  jobtitle?: string;
  gc_companysize?: string;
  mobilephone?: string;
  address1_composite?: string;
  gc_einnumber?: string;
  msdyn_company?: string;
  leadsourcecode?: number;
  }

     /**
    * NewAccountResponse entity interface.
    */
      export interface INewAccountResponse {
        Result?: string;
        }

         /**
   * NewAccountResponse entity class.
   */
  export class NewAccountResponseExtensionClass implements INewAccountResponse {

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Result: string;

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
  odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Result = odataObject.Result;

  }
}

 /**
    * OrganizationUserEntity entity interface.
    */
  export interface IOrganizationUserEntity {
    OrganizationUserName?: string;
    Addresses?: Entities.Address[];
    }

    export class OrganizationUserEntityExtensionClass implements IOrganizationUserEntity {

      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      public OrganizationUserName: string;

      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      public Addresses: Entities.Address[];

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
    odataObject = odataObject || {};
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.OrganizationUserName = odataObject.OrganizationUserName;

      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      this.Addresses = undefined;
      if (odataObject.Addresses) {
        this.Addresses = [];
        for (var i = 0; i < odataObject.Addresses.length; i++) {
          if (odataObject.Addresses[`${i}`]) {
      if (odataObject.Addresses[`${i}`]['@odata.type']) {
        var className: string = odataObject.Addresses[`${i}`]['@odata.type'];
        className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
        if (EntityClasses[`${className}`]) {
        this.Addresses[`${i}`] = new EntityClasses[`${className}`](odataObject.Addresses[`${i}`]);
        }
      } else {
        this.Addresses[`${i}`] = new EntityClasses.AddressClass(odataObject.Addresses[`${i}`]);
      }

          } else {
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.Addresses[`${i}`] = undefined;
          }
        }
      }

    }
}

  /**
    * GCWarehouseDetails entity interface.
    */
  export interface IGCWarehouseDetails {
    OdataContext?: string;
    value?: IValue2[];
    }


      /**
   * GCWarehouseDetails entity class.
   */
  export class GCWarehouseDetailsExtensionClass implements IGCWarehouseDetails {

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public OdataContext: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public value:
        IValue2[];

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
  odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.OdataContext = odataObject.OdataContext;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.value = undefined;
    if (odataObject.value) {
      this.value = [];
      for (var i = 0; i < odataObject.value.length; i++) {
        if (odataObject.value[`${i}`]) {
    if (odataObject.value[`${i}`]['@odata.type']) {
      var className: string = odataObject.value[`${i}`]['@odata.type'];
      className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
      if (EntityClasses[`${className}`]) {
        this.value[`${i}`] = new EntityClasses[`${className}`](odataObject.value[`${i}`]);
      }
    } else {
      this.value[`${i}`] = new Value2ExtensionClass(odataObject.value[`${i}`]);
    }

        } else {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.value[`${i}`] = undefined;
        }
      }
    }

  }
}

 /**
    * Value2 entity interface.
    */
 export interface IValue2 {
  OdataEtag?: string;
  dataAreaId?: string;
  ItemNumber?: string;
  ProductColorId?: string;
  ProductStyleId?: string;
  ProductVersionId?: string;
  InventorySiteId?: string;
  InventoryWarehouseId: string;
  AvailableOrderedQuantity: number;
  OnHandQuantity: number;
  AvailableOnHandQuantity: number;
  OnOrderQuantity: number;
  TotalAvailableQuantity: number;
  OrderedQuantity: number;
  AreWarehouseManagementProcessesUsed?: string;
  DistributionCenter?: string;
  ReservedOrderedQuantity: number;
  ProductName?: string;
  ReservedOnHandQuantity: number;
  }

    /**
   * Value2 entity class.
   */
    export class Value2ExtensionClass implements IValue2 {

      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      public OdataEtag: string;

      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      public dataAreaId: string;

      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      public ItemNumber: string;

      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      public ProductColorId: string;

      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      public ProductStyleId: string;

      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      public ProductVersionId: string;

      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      public InventorySiteId: string;

      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      public InventoryWarehouseId: string;

      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      public AvailableOrderedQuantity: number;

      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      public OnHandQuantity: number;

      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      public AvailableOnHandQuantity: number;

      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      public OnOrderQuantity: number;

      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      public TotalAvailableQuantity: number;

      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      public OrderedQuantity: number;

      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      public AreWarehouseManagementProcessesUsed: string;

      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      public DistributionCenter: string;

      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      public ReservedOrderedQuantity: number;

      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      public ProductName: string;

      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      public ReservedOnHandQuantity: number;

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
    odataObject = odataObject || {};
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.OdataEtag = odataObject.OdataEtag;

          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.dataAreaId = odataObject.dataAreaId;

          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.ItemNumber = odataObject.ItemNumber;

          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.ProductColorId = odataObject.ProductColorId;

          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.ProductStyleId = odataObject.ProductStyleId;

          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.ProductVersionId = odataObject.ProductVersionId;

          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.InventorySiteId = odataObject.InventorySiteId;

          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.InventoryWarehouseId = odataObject.InventoryWarehouseId;

          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.AvailableOrderedQuantity = (odataObject.AvailableOrderedQuantity) ? parseInt(odataObject.AvailableOrderedQuantity, 10) : 0;

          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.OnHandQuantity = (odataObject.OnHandQuantity) ? parseInt(odataObject.OnHandQuantity, 10) : 0;

          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.AvailableOnHandQuantity = (odataObject.AvailableOnHandQuantity) ? parseInt(odataObject.AvailableOnHandQuantity, 10) : 0;

          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.OnOrderQuantity = (odataObject.OnOrderQuantity) ? parseInt(odataObject.OnOrderQuantity, 10) : 0;

          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.TotalAvailableQuantity = (odataObject.TotalAvailableQuantity) ? parseInt(odataObject.TotalAvailableQuantity, 10) : 0;

          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.OrderedQuantity = (odataObject.OrderedQuantity) ? parseInt(odataObject.OrderedQuantity, 10) : 0;

          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.AreWarehouseManagementProcessesUsed = odataObject.AreWarehouseManagementProcessesUsed;

          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.DistributionCenter = odataObject.DistributionCenter;

          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.ReservedOrderedQuantity = (odataObject.ReservedOrderedQuantity) ? parseInt(odataObject.ReservedOrderedQuantity, 10) : 0;

          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.ProductName = odataObject.ProductName;

          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.ReservedOnHandQuantity = (odataObject.ReservedOnHandQuantity) ? parseInt(odataObject.ReservedOnHandQuantity, 10) : 0;

    }
}

/**
   * GCProductWiseWarehouseDetails entity class.
   */
export class GCProductWiseWarehouseDetailsExtensionClass implements IGCProductWiseWarehouseDetails {

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public ItemId: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public warehouseDetails:
      IGCWarehouseDetails;

// Navigation properties names

/**
 * Construct an object from odata response.
 * @param {any} odataObject The odata result object.
 */
constructor(odataObject?: any) {
odataObject = odataObject || {};
      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      this.ItemId = odataObject.ItemId;

  if (odataObject.warehouseDetails === null) {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.warehouseDetails = undefined;
  } else if (odataObject.warehouseDetails !== undefined && odataObject.warehouseDetails['@odata.type'] === null) {
    this.warehouseDetails = new GCWarehouseDetailsExtensionClass(odataObject.warehouseDetails);
  } else {
    this.warehouseDetails = new GCWarehouseDetailsExtensionClass(odataObject.warehouseDetails);
    // var className: string = odataObject.warehouseDetails['@odata.type'];
    // if(className !== undefined && className.length > 0)
    // {
    //   className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
    //   if (EntityClasses[className]) {
    //     this.warehouseDetails = new EntityClasses[className](odataObject.warehouseDetails);
    //   }
    // }
  }


}
}

    /**
    * GCProductWiseWarehouseDetails entity interface.
    */
    export interface IGCProductWiseWarehouseDetails {
      ItemId?: string;
      warehouseDetails?: IGCWarehouseDetails;
      }

       /**
    * GCProductIds entity interface.
    */
    export interface IGCProductIds {
      prodIds?: string[];
      }

  /**
   * GCProductIds entity class.
   */
  export class GCProductIdsExtensionClass implements IGCProductIds {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public prodIds: string[];

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
  odataObject = odataObject || {};
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    this.prodIds = undefined;
    if (odataObject.prodIds) {
      this.prodIds = [];
      for (var i = 0; i < odataObject.prodIds.length; i++) {
        if (odataObject.prodIds[`${i}`]) {
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.prodIds[`${i}`] =
            odataObject.prodIds[`${i}`];
        } else {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.prodIds[`${i}`] = undefined;
        }
      }
    }

  }
}

 /**
    * ProductIdWiseETADetails entity interface.
    */
 export interface IProductIdWiseETADetails {
  ItemId?: string;
  etaInfo?: IProductETAInfo;
  }

    /**
   * ProductIdWiseETADetails entity class.
   */
    export class ProductIdWiseETADetailsExtensionClass implements IProductIdWiseETADetails {

      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      public ItemId: string;

      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      public etaInfo:
          IProductETAInfo;

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
    odataObject = odataObject || {};
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.ItemId = odataObject.ItemId;

      if (odataObject.etaInfo === undefined || odataObject.etaInfo === null) {
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.etaInfo = undefined;
      } else if (odataObject.etaInfo !== undefined && odataObject.etaInfo['@odata.type'] === null || odataObject.etaInfo['@odata.type'] === undefined) {
        this.etaInfo = new ProductETAInfoExtensionClass(odataObject.etaInfo);
      } else {
        var className: string = odataObject.etaInfo && odataObject.etaInfo['@odata.type'];
        if(className !== undefined && className.length > 0)
        {
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[`${className}`]) {
            this.etaInfo = new EntityClasses[`${className}`](odataObject.etaInfo);
          }
        }
      }


    }
}

  /**
   * ProductETAInfo entity class.
   */
  export class ProductETAInfoExtensionClass implements IProductETAInfo {

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ErrorMessage: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ETADate: string;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public ResultMessage: string;

  // Navigation properties names

  /**
   * Construct an object from odata response.
   * @param {any} odataObject The odata result object.
   */
  constructor(odataObject?: any) {
  odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ErrorMessage = odataObject.ErrorMessage;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ETADate = odataObject.ETADate;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ResultMessage = odataObject.ResultMessage;

  }
}

    /**
    * GCGetCustomTradeAgreementResponse entity interface.
    */
    export interface IGCGetCustomTradeAgreementResponse {
      gcProductTradeAgreementsEntity?: IGCProductTradeAgreementsEntity[];
      }

 /**
    * GCProductTradeAgreementsEntity entity interface.
    */
 export interface IGCProductTradeAgreementsEntity {
  ProductRecId: number;
  ItemId?: string;
  TradeAgreementPrice: number;
  PriceGroup?: string;
  UnitOfMeasureSymbol?: string;
  ExtensionProperties?: Entities.CommerceProperty[];
  }

/**
   * GCGetCustomTradeAgreementResponse entity class.
   */
export class GCGetCustomTradeAgreementResponseExtensionClass implements IGCGetCustomTradeAgreementResponse {

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public gcProductTradeAgreementsEntity:
      IGCProductTradeAgreementsEntity[];

// Navigation properties names

/**
 * Construct an object from odata response.
 * @param {any} odataObject The odata result object.
 */
constructor(odataObject?: any) {
odataObject = odataObject || {};
  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  this.gcProductTradeAgreementsEntity = undefined;
  if (odataObject.gcProductTradeAgreementsEntity) {
    this.gcProductTradeAgreementsEntity = [];
    for (var i = 0; i < odataObject.gcProductTradeAgreementsEntity.length; i++) {
      if (odataObject.gcProductTradeAgreementsEntity[i]) {
  if (odataObject.gcProductTradeAgreementsEntity[i]['@odata.type']) {
    var className: string = odataObject.gcProductTradeAgreementsEntity[i]['@odata.type'];
    className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
    if (EntityClasses[className]) {
      this.gcProductTradeAgreementsEntity[i] = new EntityClasses[className](odataObject.gcProductTradeAgreementsEntity[i]);
    }
  } else {
    this.gcProductTradeAgreementsEntity[i] = new GCProductTradeAgreementsEntityExtensionClass(odataObject.gcProductTradeAgreementsEntity[i]);
  }

      } else {
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.gcProductTradeAgreementsEntity[i] = undefined;
      }
    }
  }

}
}

/**
   * GCProductTradeAgreementsEntity entity class.
   */
export class GCProductTradeAgreementsEntityExtensionClass implements IGCProductTradeAgreementsEntity {

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public ProductRecId: number;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public ItemId: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public TradeAgreementPrice: number;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public PriceGroup: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public UnitOfMeasureSymbol: string;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  public ExtensionProperties: Entities.CommerceProperty[];

// Navigation properties names

/**
 * Construct an object from odata response.
 * @param {any} odataObject The odata result object.
 */
constructor(odataObject?: any) {
odataObject = odataObject || {};
      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      this.ProductRecId = (odataObject.ProductRecId) ? parseInt(odataObject.ProductRecId, 10) : 0;

      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      this.ItemId = odataObject.ItemId;

      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      this.TradeAgreementPrice = (odataObject.TradeAgreementPrice) ? parseFloat(odataObject.TradeAgreementPrice) : 0;

      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      this.PriceGroup = odataObject.PriceGroup;

      // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
      this.UnitOfMeasureSymbol = odataObject.UnitOfMeasureSymbol;

  // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
  this.ExtensionProperties = undefined;
  if (odataObject.ExtensionProperties) {
    this.ExtensionProperties = [];
    for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
      if (odataObject.ExtensionProperties[i]) {
  if (odataObject.ExtensionProperties[i]['@odata.type']) {
    var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
    className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
    if (EntityClasses[className]) {
      this.ExtensionProperties[i] = new EntityClasses[className](odataObject.ExtensionProperties[i]);
    }
  } else {
    this.ExtensionProperties[i] = new EntityClasses.CommercePropertyClass(odataObject.ExtensionProperties[i]);
  }

      } else {
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ExtensionProperties[i] = undefined;
      }
    }
  }

}
}